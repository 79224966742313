import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, Alert } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from 'react';

export const AssignGrampanchyat = ({ getAllTrainingModuleData, getAllTrainingCategoryData, getDistrictWiseTalukaData, getAllDistrictData, getTalukaWisePanchayat,userDetails,AssignModuleToPanchyat,getAllAssignedPanchayat }) => {

  // hook variable
  const classes = useStyles()

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Modal Variables
  const [AssignModal, setAssignModal] = useState(false)

  //Array variables
  const [ModuleList, setModuleList] = useState([])
  const [CategoryList, setCategoryList] = useState([])
  const [DistrictList, setDistrictList] = useState([])
  const [TalukaList, setTalukaList] = useState([])
  const [PanchayatList, setPanchayatList] = useState([])

  //data variables
  const [SelectedModule, setSelectedModule] = useState('')
  const [SelectedCategory, setSelectedCategory] = useState('')
  const [SelectedDistrict, setSelectedDistrict] = useState('')
  const [SelectedTaluka, setSelectedTaluka] = useState('')
  const [SelectedPanchyat, setSelectedPanchyat] = useState('')
  const [GuideLines, setGuideLines] = useState([])
  const [AssignedList,setAssignedList] = useState([])

  //Error variables
  const [talukaError, settalukaError] = useState(false)
  const [panchyatError, setPanchyatError] = useState(false)

  // useEffects


  useEffect(() => {
    getModule()
    getCategory()
    getDistrict()
    GetAssignedData()
  }, [])

  useEffect(() => {
    if (SelectedDistrict) {
      getTaluka()
    }
  }, [SelectedDistrict])

  useEffect(() => {
    if (SelectedTaluka) {
      getPanchayat()
    }
  }, [SelectedTaluka])


  //get 

  const getModule = async () => {
    const result = await getAllTrainingModuleData()
    if (result !== 'No data found') {
      setModuleList(result)
      // console.log("ModuleList", result)
    }
  }

  const getCategory = async () => {
    const result = await getAllTrainingCategoryData()
    if (result !== 'No data found') {
      setCategoryList(result)
      // console.log("CategoryList", result)
    }
  }

  const getDistrict = async () => {
    const result = await getAllDistrictData()
    if (result !== 'No data found') {
      setDistrictList(result)
      // console.log(result)
    }
  }

  const getTaluka = async () => {
    settalukaError(false)
    const result = await getDistrictWiseTalukaData({
      distric_id: SelectedDistrict?._id
    })
    if (result !== 'No data found') {
      setTalukaList(result)
      // console.log("Taluka list", result)
    } else {
      settalukaError(true)
    }
  }

  const getPanchayat = async () => {
    setPanchyatError(false)
    const result = await getTalukaWisePanchayat({
      distric_id: SelectedDistrict?._id,
      taluka_id: SelectedTaluka?._id
    })
    if (result !== 'No data found') {
      setPanchayatList(result)
    } else {
      setPanchyatError(true)
    }
  }

  //All Assigned Panchyat

  const GetAssignedData = async () => {
    const result = await getAllAssignedPanchayat()
    if(result){
      setAssignedList(result)
    }
  }

  // Open Modal
  const openModal = () => {
    setSelectedDistrict('')
    setSelectedTaluka('')
    setSelectedPanchyat('')
    setSelectedModule('')
    setGuideLines('')
    setAssignModal(true)
  }

  //Assign Module

  const handleSubmit = async () => {
    const result = await AssignModuleToPanchyat({
      trainingModule_id:SelectedModule?._id,
      trainingModuleName:SelectedModule?.trainingModuleName,
      grampanchayat_id:SelectedPanchyat?._id,
      grampanchayatName:SelectedPanchyat?.grampanchayatName,
      trainingGuideline:GuideLines,
      distric_id:SelectedDistrict?._id,
      districtName:SelectedDistrict?.districtName,
      createdBy:userDetails?._id
    })
    if(result){
      // console.log("Assigned Panchyat",result)
      setAssignModal(false)
      GetAssignedData()
    }
    
  }

  return (
    <div className={classes.main}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <h2>Assigned List Data</h2>
        </Box>
        <Box >
          <div style={{ right: 0, marginTop: 10 }}>
            <Button variant="contained" onClick={() => openModal()} >Assign Module</Button>
          </div>
        </Box>
      </div>

      {/* Assigned Panchyat List */}
      <Paper sx={{ overflow: 'hidden' }}>
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontSize:18,textAlign:'center',fontWeight:600 }}>District Name</TableCell>
              <TableCell style={{ fontSize:18,textAlign:'center',fontWeight:600 }}>School Name</TableCell>
              <TableCell style={{ fontSize:18,textAlign:'center',fontWeight:600 }}>Assigned Module</TableCell>
              <TableCell style={{ fontSize:18,textAlign:'center',fontWeight:600 }}>Training guidlines</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {
              AssignedList !== 'No data found' ? AssignedList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( (row,index) => {
                return(
                  <TableRow key={row?._id}>
                    <TableCell style={{ fontSize:16,textAlign:'center' }}>{row?.districtName}</TableCell>
                    <TableCell style={{ fontSize:16,textAlign:'center' }}>{row?.grampanchayatName}</TableCell>
                    <TableCell style={{ fontSize:16,textAlign:'center' }}>{row?.trainingModuleName}</TableCell>
                    <TableCell style={{ fontSize:16,textAlign:'center' }}>{row?.trainingGuideline}</TableCell>
                  </TableRow>
                )
              } )
               : <TableRow>NO Data Found</TableRow>
            }
          </TableBody>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={AssignedList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Assign Module Modal */}
      <Modal
        className={classes.middlePosition}
        open={AssignModal}
        onClose={(e) => {
          e.preventDefault();
          setAssignModal(false)
        }}
      >
        <Paper className={classes.profilemodal} >
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Assign Module to Grampanchayat</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setAssignModal(false)
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControl sx={{ m: 1, marginRight: 3 }}>
                <InputLabel id="outlined-adornment-District">District</InputLabel>
                <Select
                  style={{ width: 250 }}
                  id="outlined-adornment-District"
                  label="District"
                  value={SelectedDistrict}
                  onChange={(e) => { setSelectedDistrict(e.target.value) }}
                  disabled={DistrictList.length > 0 ? false : true}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                >
                  {DistrictList && DistrictList?.map((item) => {
                    return (
                      <MenuItem key={item?._id} value={item}>
                        {item?.districtName}
                      </MenuItem>
                    )
                  })}
                </Select>

              </FormControl>

              <FormControl sx={{ m: 1, marginRight: 3 }}>
                <InputLabel id="outlined-adornment-Taluka">Taluka</InputLabel>
                <Select
                  style={{ width: 250 }}
                  id="outlined-adornment-Taluka"
                  label="Taluka"
                  value={SelectedTaluka}
                  onChange={(e) => { setSelectedTaluka(e.target.value) }}
                  disabled={TalukaList.length > 0 ? false : true}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                >
                  {TalukaList && TalukaList?.map((item) => {
                    return (
                      <MenuItem key={item?._id} value={item}>
                        {item?.talukaName}
                      </MenuItem>
                    )
                  })}
                </Select>
                {talukaError ? <span style={{ color: 'red' }}>*No Taluka found </span> : null}
              </FormControl>

              <FormControl sx={{ m: 1, marginRight: 3 }}>
                <InputLabel id="outlined-adornment-Grampanchayat">Grampanchayat</InputLabel>
                <Select
                  style={{ width: 250 }}
                  id="outlined-adornment-Grampanchayat"
                  label="Grampanchayat"
                  value={SelectedPanchyat}
                  onChange={(e) => { setSelectedPanchyat(e.target.value) }}
                  disabled={PanchayatList.length > 0 ? false : true}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                >
                  {PanchayatList && PanchayatList?.map((item) => {
                    return (
                      <MenuItem key={item?._id} value={item}>
                        {item?.grampanchayatName}
                      </MenuItem>
                    )
                  })}
                </Select>
                {panchyatError ? <span style={{ color: 'red' }}>*No Grampanchayat found </span> : null}
              </FormControl>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControl sx={{ m: 1, marginRight: 5 }}>
                <InputLabel id="outlined-adornment-Module">Module</InputLabel>
                <Select
                  style={{ width: 250 }}
                  id="outlined-adornment-Module"
                  label="Module"
                  value={SelectedModule}
                  onChange={(e) => { setSelectedModule(e.target.value) }}
                  disabled={ModuleList.length > 0 ? false : true}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                >
                  {ModuleList && ModuleList?.map((item) => {
                    return (
                      <MenuItem key={item?._id} value={item}>
                        {item?.trainingModuleName}
                      </MenuItem>
                    )
                  })}
                </Select>

              </FormControl>

              {/* <FormControl sx={{ m: 1, marginRight: 5 }}>
                <InputLabel id="outlined-adornment-Module">Training Category</InputLabel>
                <Select
                  style={{ width: 250 }}
                  id="outlined-adornment-Module"
                  label="Training Category"
                  value={SelectedCategory}
                  onChange={(e) => { setSelectedCategory(e.target.value) }}
                  disabled={CategoryList.length > 0 ? false : true}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                >
                  {CategoryList && CategoryList?.map((item) => {
                    return (
                      <MenuItem key={item?._id} value={item}>
                        {item?.trainingCategoryName}
                      </MenuItem>
                    )
                  })}
                </Select>

              </FormControl> */}
              <FormControl sx={{ m: 1, marginRight: 5 }}>
                <TextField
                  style={{ width: 250 }}
                  id="outlined-adornment-TrainerName"
                  label="Guidlines"
                  value={GuideLines}
                  onChange={ (e) => setGuideLines(e.target.value) }
                />
              </FormControl>
            </div>
            <center>
              <Button variant='contained' style={{ margin:10 }} onClick={ () => handleSubmit() }>Assign Module</Button>
            </center>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '1000px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
}));

const mapStateToProps = (state) => {
  return{
    userDetails: state.auth.userDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAllTrainingModuleData: () => dispatch(Action.getAllTrainingModuleData()),
    getAllTrainingCategoryData: () => dispatch(Action.getAllTrainingCategoryData()),
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getDistrictWiseTalukaData: (data) => dispatch(Action.getDistrictWiseTalukaData(data)),
    getTalukaWisePanchayat: (data) => dispatch(Action.getTalukaWisePanchayat(data)),
    AssignModuleToPanchyat: (data) => dispatch(Action.AssignModuleToPanchyat(data)),
    getAllAssignedPanchayat: () => dispatch(Action.getAllAssignedPanchayat()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignGrampanchyat)