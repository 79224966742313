import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  CircularProgress,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Autocomplete,
  Modal,
  IconButton,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import PaginationComponent from "../../Components/pagination";
import BarChartIcon from "@mui/icons-material/BarChart";

export const AssignedTrainerList = ({
  getAllAssignedTrainer,
  getAllDatewiseAssignedTrainer,
  getAllDistrictData,
  DistrictWisePanchayat,
  getAllDistrictwiseAssignedTrainer,
  getAllTrainers,
  DeleteTrainingRecord,
  userDetails,
  getAllAssignedTrainerByTrainerId,
}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  //data variables
  const [trainerData, setTrainerData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [cityData, setcityData] = useState([]);
  const [PanchayatData, setPanchayatData] = useState([]);
  const [panchayat, setPanchayat] = useState("");
  const [city, setCity] = useState("");

  //Delete Training
  const [deleteId, setDeleteId] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  // Error variables
  const [dateError, setdateError] = useState(false);
  const [InvalidDate, setInvalidDate] = useState(false);
  const [cityError, setcityError] = useState(false);
  const [PanchayatError, setPanchayatError] = useState(false);
  const [DisPanError, setDisPanError] = useState(false);

  //Filter
  const [searchTrainerError, setsearchTrainerError] = useState(false);
  const [searchTrainer, setsearchTrainer] = useState(null);
  const [userData, setuserData] = useState([]);

  //Table Pagination variables
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [noOfPages, setNoOfPages] = useState();

  //Table Pagination Functions
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event);
    setPage(1);
  };

  useEffect(() => {
    // getTrainerData()
    getDistrict();
    getTrainers();
  }, []);

  useEffect(() => {
    if (city && panchayat) {
    } else if (searchTrainer) {
      getListByTrainer();
    } else {
      getTrainerData();
    }
  }, [page, rowsPerPage, searchTrainer]);

  useEffect(() => {
    if (city) {
      getPanchayat();
    }
  }, [city]);

  // useEffect(() => {
  //   if (searchTrainer === null) {
  //     console.log("Calling to search")
  //     getTrainerData();
  //   }
  // }, [searchTrainer]);

  //GET Trianer data
  const getTrainerData = async () => {
    setLoader(true);
    const result = await getAllAssignedTrainer({
      documentsPerPage: rowsPerPage,
      page: page,
    });
    console.log(result);
    if (result !== "No data fonund" && result !== undefined) {
      // console.log("Getting Data", result);
      setTrainerData(result);
      setNoOfPages(result.noOfPages);
      setFilterData(result);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getDateWise = async () => {
    setLoader(true);
    setdateError(false);
    setInvalidDate(false);
    if (endDate < startDate) {
      // console.log("Invalid");
      setLoader(false);
      setInvalidDate(true);
    } else {
      if (startDate !== null && endDate !== null) {
        const result = await getAllDatewiseAssignedTrainer({
          start: moment(startDate.$d).format("YYYY-MM-DD"),
          end: moment(endDate.$d).format("YYYY-MM-DD"),
        });
        if (result) {
          setTrainerData(result);
          setNoOfPages(result.noOfPages);
          setLoader(false);
        } else {
          setLoader(false);
        }
      } else {
        setLoader(false);
        setdateError(true);
      }
    }
  };

  const getDistrict = async () => {
    const result = await getAllDistrictData();

    if (result !== "No data found") {
      setcityData(result);
    }
  };

  const getPanchayat = async () => {
    let arr = [];
    arr.push(city?._id);
    const result = await DistrictWisePanchayat({
      distric_id: arr,
    });
    if (result !== "No data found") {
      setPanchayatData(result);
    }
  };

  const getDistrictWise = async () => {
    setDisPanError(false);
    if (city && panchayat) {
      const result = await getAllDistrictwiseAssignedTrainer({
        distric_id: city?._id,
        grampanchayat_id: panchayat?._id,
        documentsPerPage: rowsPerPage,
        page: page,
      });
      if (result) {
        setTrainerData(result);
        setNoOfPages(result.noOfPages);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } else {
      setDisPanError(true);
    }
  };

  const getTrainers = async () => {
    const result = await getAllTrainers();
    if (result) {
      setuserData(result);
    }
  };

  const getSingleUser = (value) => {
    console.log("Trainer", value);
    setsearchTrainer(value);
  };

  const getListByTrainer = async () => {
    setLoader(true);
    const result = await getAllAssignedTrainerByTrainerId({
      documentsPerPage: rowsPerPage,
      page: page,
      trainer_id: searchTrainer._id,
    });
    console.log(result);
    if (result !== "No data fonund" && result !== undefined) {
      // console.log("Getting Data", result);
      setTrainerData(result);
      setNoOfPages(result.noOfPages);
      setFilterData(result);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const showDelete = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };

  const DeleteTraining = async () => {
    const result = await DeleteTrainingRecord({
      trainingAssignTrainer_id: deleteId,
      updatedBy: userDetails?._id,
    });
    if (result) {
      // console.log("Training Deleted", result);
      setOpenDelete(false);
      setConfirmDelete(true);
      getTrainerData();
    }
  };

  return (
    <div className={classes.main}>
      <center>
        <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>
          All Assigned Trainers
        </Typography>
      </center>
      <Box style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                setstartDate(newValue);
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              minDate={startDate}
              onChange={(newValue) => {
                setendDate(newValue);
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{ margin: 5, width: 170, height: 50 }}
            onClick={() => getDateWise()}
          >
            Get Data Datewise
          </Button>
          {dateError ? (
            <span style={{ color: "red" }}>*Please Select Both Dates</span>
          ) : null}
          {InvalidDate ? (
            <span style={{ color: "red" }}>
              *End Date cannot be less than Start date
            </span>
          ) : null}
        </div>
        <Button
          variant="contained"
          style={{ margin: 10, width: 130, height: 50 }}
          onClick={() => {
            getTrainerData();
            setCity("")
            setPanchayat("")
          }}
        >
          GET All Data
        </Button>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          flexWrap: "wrap",
        }}
      >
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">District</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-Bank"
            label="District"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            disabled={cityData === undefined || cityData.length === 0}
          >
            {cityData &&
              cityData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.districtName}
                  </MenuItem>
                );
              })}
          </Select>
          {cityError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select District
            </span>
          ) : null}
        </FormControl>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">School</InputLabel>
          <Select
            style={{ width: 200 }}
            id="outlined-adornment-month"
            label="School"
            value={panchayat}
            disabled={PanchayatData.length === 0}
            onChange={(e) => {
              setPanchayat(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {PanchayatData &&
              PanchayatData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.schoolName}
                  </MenuItem>
                );
              })}
          </Select>
          {PanchayatError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select School
            </span>
          ) : null}
        </FormControl>
        <div style={{ margin: 5, display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{ margin: 10, height: 50, width: 180 }}
            onClick={() => getDistrictWise()}
          >
            Get School wise data
          </Button>
          {DisPanError ? (
            <span style={{ color: "red", flexWrap: "wrap" }}>
              *Please Select Both District and School
            </span>
          ) : null}
        </div>
      </Box>
      {/* Filter By Trainer */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 20,
          marginBottom: 30,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Autocomplete
            id="Trainer-data"
            sx={{ width: 300 }}
            options={userData}
            autoHighlight
            disablePortal
            freeSolo
            getOptionLabel={(option) =>
              option?.firstName +
              " " +
              option?.lastName +
              " - " +
              option?.contactNo
            }
            renderInput={(params) => (
              <TextField {...params} label="Search By Trainer" />
            )}
            // value={searchTrainer}
            onChange={(event, newValue) => {
              // setsearchTrainer(newValue);
              getSingleUser(newValue);
            }}
          />
          {searchTrainerError ? (
            <span style={{ color: "red" }}>*Please Select trainer first</span>
          ) : null}
        </div>
      </div>
      {loader ? (
        <CircularProgress color="primary" sx={{ marginTop: 3 }} />
      ) : (
        <Paper sx={{ overflow: "hidden", marginTop: 3 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>SR No.</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>District Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>School Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Trainer Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Support Trainer Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Training Module</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Assignment Date</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Shift</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Action</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(trainerData).length !== 0 &&
                trainerData !== "No data found" ? (
                  trainerData.data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {index + 1 + rowsPerPage * (page - 1)}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.districtName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.grampanchayatName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainerNameAll[0]
                            ? row?.trainerNameAll[0]
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainerNameAll[1]
                            ? row?.trainerNameAll[1]
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.trainingModuleName}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {moment(row?.startDate).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: 16, textAlign: "center" }}
                        >
                          {row?.shift ? row?.shift.toUpperCase() : "-"}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: 16,
                            textAlign: "center",
                            width: 100,
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{ margin: 5, fontSize: 12, padding: 5 }}
                            onClick={() =>
                              navigate("/admin/Trainer-Reassign", {
                                state: row,
                              })
                            }
                          >
                            Reassign/Unassign Trainer
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => showDelete(row?._id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>NO Data Found</TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                <Typography variant="h6" component="div" textAlign="center">
                  <IconButton>
                    <BarChartIcon />
                  </IconButton>
                  Total - <strong>{trainerData?.count}</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                display="flex"
                justifyContent="flex-end"
              >
                <PaginationComponent
                  currentPage={page}
                  totalPages={noOfPages}
                  onPageChange={handleChangePage}
                  onDocsPerPageChange={handleChangeRowsPerPage}
                  docsPerPage={rowsPerPage}
                />
              </Grid>
            </Grid>
          </Box>

          {/* <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            // count={count ? count : 0}
                            count={ trainerData !== "No data found" && trainerData.data?.length > 0 ? trainerData.data?.length : 0 }
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
        </Paper>
      )}

      <Modal
        className={classes.middlePosition}
        open={openDelete}
        onClose={(e) => {
          e.preventDefault();
          setOpenDelete(false);
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Are you sure ?
              </Typography>
            </Stack>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setOpenDelete(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <center>
            <Button
              variant="contained"
              style={{ backgroundColor: "grey", width: 100, margin: 5 }}
              onClick={() => {
                setOpenDelete(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "red", width: 100, margin: 5 }}
              onClick={() => {
                DeleteTraining();
              }}
            >
              Delete
            </Button>
          </center>
        </Paper>
      </Modal>

      <Modal
        className={classes.middlePosition}
        open={confirmDelete}
        onClose={(e) => {
          e.preventDefault();
          setConfirmDelete(false);
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Training Deleted Successfully !!!
              </Typography>
            </Stack>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setConfirmDelete(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <center>
            <Button
              variant="contained"
              style={{ margin: 5 }}
              onClick={() => {
                setConfirmDelete(false);
              }}
            >
              OK
            </Button>
          </center>
        </Paper>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "1200px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1400px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllAssignedTrainer: (data) =>
      dispatch(Action.getAllAssignedTrainer(data)),
    getAllAssignedTrainerByTrainerId: (data) =>
      dispatch(Action.getAllAssignedTrainerByTrainerId(data)),
    getAllDatewiseAssignedTrainer: (data) =>
      dispatch(Action.getAllDatewiseAssignedTrainer(data)),
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    DistrictWisePanchayat: (data) =>
      dispatch(Action.DistrictWisePanchayat(data)),
    getAllDistrictwiseAssignedTrainer: (data) =>
      dispatch(Action.getAllDistrictwiseAssignedTrainer(data)),
    getAllTrainers: () => dispatch(Action.getAllTrainers()),
    DeleteTrainingRecord: (data) => dispatch(Action.DeleteTrainingRecord(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignedTrainerList);
