import React, { useState } from "react";
import { connect } from "react-redux";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import { useEffect } from "react";
import * as Action from "../../actions/adminAction";
import { Edit, Visibility, VisibilityOff, Delete, CheckBox } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Alert,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import axios from "axios";
// import makeStyles from "@mui/material";

const User = [
  {
    id: 1,
    label: "admin",
    key: 'admin'
  },
  {
    id: 2,
    label: "district coordinator",
    key: 'dc'
  },
  {
    id: 3,
    label: "trainer",
    key: 'trainer'
  },
  // {
  //   id: 4,
  //   label: "gramsevak",
  //   key: 'gramsevak'
  // },
  // {
  //   id: 5,
  //   label: "sarpanch",
  //   key: 'sarpanch'
  // },
  // {
  //   id: 6,
  //   label: "beneficiary",
  //   key: 'beneficiary'
  // },
  {
    id: 7,
    label: "quality checker",
    key: 'qc'
  },
  {
    id: 8,
    label: 'Client',
    key: 'client'
  }
];

const data = [
  {
    PanchyatName: "ADEGAON",
    description: "Implementing Bhujal Yojana",
    TalukaName: "Bhor",
    DistrictName: "Pune",
    SarpanchName: "XYZ",
    GramsevakName: "auyda",
  },
];

export const AddUser = ({
  getAllDistrictData,
  getAllTalukaData,
  getAllgrampanchayatdata,
  getDistrictWiseTalukaData,
  getTalukaWisePanchayat
}) => {

  const classes = useStyles();
  const navigate = useNavigate();
  //Modal variables
  const [Adduser, setAddUser] = useState(true);
  //data variables
  const [TrainingModuleName, setTrainingModuleName] = useState("");
  const [usertype, setUserType] = useState("");
  const [GramPanchayat, setGramPanchayat] = useState("");
  const [District, setDistrict] = useState("");
  const [loading, setloading] = useState(false);
  const [taluka, setTaluka] = useState("");
  const [PermanentGramPanchayat, setPermanentGramPanchayat] = useState("");
  const [PermanentDistrict, setPermanentDistrict] = useState("");
  const [Permanenttaluka, setPermanentTaluka] = useState("");
  const [preferredGramPanchayat, setpreferredGramPanchayat] = useState("");
  const [preferredDistrict, setpreferredDistrict] = useState("");
  const [preferredtaluka, setpreferredTaluka] = useState("");

  const [TalukaData, setTalukaData] = useState([]);
  const [GramPanchayatData, setGramPanchayatData] = useState([]);
  const [DistrictData, setDistrictData] = useState([]);
  const [PermanantDistrictData, setPermanantDistrictData] = useState([])
  const [PermanantTalukaData, setPermanantTalukaData] = useState([])
  const [PermanantPanchayatData, setPermanantPanchayatData] = useState([])
  const [PreferredDistrictData, setPreferredDistrictData] = useState([])
  const [PreferredTalukaData, setPreferredTalukaData] = useState([])
  const [PreferredPanchayatData, setPreferredPanchayatData] = useState([])

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contact, setContact] = useState("");
  const [whatsup, setwhatsup] = useState("");
  const [email, setEmail] = useState("");
  const [currentAdd, setCurrentAdd] = useState("");
  const [currentAddlinetwo, setcurrentAddlinetwo] = useState("");
  const [permanentAddlinetwo, setpermanentAddlinetwo] = useState("");
  const [profilePicURL, setprofilePicURL] = useState("");
  const [permanentAdd, setPermanentAdd] = useState("");
  const [permanentpincode, setpermanentPincode] = useState("");
  const [pincode, setPincode] = useState("");
  const [currentstate, setcurrentstate] = useState("");
  const [permanentstate, setpermanentstate] = useState("");
  const [password, setPassword] = useState("");
  const [imgArray, setImgArray] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [imgUploadingError, setImgUploadingError] = useState(false);
  const [imgUploading, setImgUploading] = useState(false);
  //Error variables
  const [error, setError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [fnameError, setfnameError] = useState(false);
  const [lnameError, setlnameError] = useState(false);
  const [contactError, setcontactError] = useState(false);
  const [whatsupcontactError, setwhatsupcontactError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [currAddError, setcurrAddError] = useState(false);
  const [currAddtwoError, setcurrAddtwoError] = useState(false);
  const [currAddgrampanchaytError, setcurrAddgrampanchaytError] =
    useState(false);
  const [currAddtalukaError, setcurrAddtalukaError] = useState(false);
  const [currAdddistrictError, setcurrAdddistrictError] = useState(false);
  const [currAddstateError, setcurrAddstateError] = useState(false);
  const [preffAddtalukaError, setpreffAddtalukaError] = useState(false);
  const [preffAdddistrictError, setpreffAdddistrictError] = useState(false);
  const [preffAddgrampanchayatError, setpreffAddgrampanchayatError] =
    useState(false);
  const [PermaAddError, setPermaAddError] = useState(false);
  const [pinError, setpinError] = useState(false);
  const [usertypeError, setusertypeError] = useState(false);
  const [distError, setdistError] = useState(false);
  const [talukaError, settalukaError] = useState(false);
  const [GramPanchayatError, setGramPanchayatError] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [SameAsAbove, setSameAsAbove] = useState(false)
  const [userExist, setUserExist] = useState(false)

  const getDistrict = async () => {
    const result = await getAllDistrictData();
    if (result !== "No data found") {
      setDistrictData(result);
      setPermanantDistrictData(result);
      setPreferredDistrictData(result);
    }
  };

  // const getTaluka = async () => {
  //   const result = await getAllTalukaData();
  //   if (result !== "No data found") {
  //     setTalukaData(result);
  //   }
  // };
  // const getGrampanchayat = async () => {
  //   const result = await getAllgrampanchayatdata();
  //   if (result !== "No data found") {
  //     setGramPanchayatData(result);
  //   }
  // };

  const getTaluka = async () => {
    settalukaError(false)
    const result = await getDistrictWiseTalukaData({
      distric_id: District?._id
    })
    if (result !== 'No data found') {
      setTalukaData(result)
      // console.log("Taluka list", result)
    } else {
      settalukaError(true)
    }
  }

  const getPanchayat = async () => {
    setGramPanchayatError(false)
    const result = await getTalukaWisePanchayat({
      distric_id: District?._id,
      taluka_id: taluka?._id
    })
    if (result !== 'No data found') {
      setGramPanchayatData(result)
    } else {
      setGramPanchayatError(true)
    }
  }

  const getPermantTaluka = async () => {
    settalukaError(false)
    const result = await getDistrictWiseTalukaData({
      distric_id: PermanentDistrict?._id
    })
    if (result !== 'No data found') {
      setPermanantTalukaData(result)
      // console.log("Taluka list", result)
    } else {
      settalukaError(true)
    }
  }

  const getPermantPanchayat = async () => {
    setGramPanchayatError(false)
    const result = await getTalukaWisePanchayat({
      distric_id: PermanentDistrict?._id,
      taluka_id: Permanenttaluka?._id
    })
    if (result !== 'No data found') {
      setPermanantPanchayatData(result)
    } else {
      setGramPanchayatError(true)
    }
  }

  const getPreferredTaluka = async () => {
    settalukaError(false)
    const result = await getDistrictWiseTalukaData({
      distric_id: preferredDistrict?._id
    })
    if (result !== 'No data found') {
      setPreferredTalukaData(result)
      // console.log("Taluka list", result)
    } else {
      settalukaError(true)
    }
  }

  const getPreferredPanchayat = async () => {
    setGramPanchayatError(false)
    const result = await getTalukaWisePanchayat({
      distric_id: preferredDistrict?._id,
      taluka_id: preferredtaluka?._id
    })
    if (result !== 'No data found') {
      setPreferredPanchayatData(result)
    } else {
      setGramPanchayatError(true)
    }
  }



  useEffect(() => {
    getDistrict();
  }, []);

  useEffect(() => {
    if (District) {
      getTaluka()
    }
  }, [District])

  useEffect(() => {
    if (taluka) {
      getPanchayat()
    }
  }, [taluka])

  useEffect(() => {
    if (PermanentDistrict) {
      getPermantTaluka()
    }
  }, [PermanentDistrict])

  useEffect(() => {
    if (Permanenttaluka) {
      getPermantPanchayat()
    }
  }, [Permanenttaluka])

  useEffect(() => {
    if (preferredDistrict) {
      getPreferredTaluka()
    }
  }, [preferredDistrict])

  useEffect(() => {
    if (preferredtaluka) {
      getPreferredPanchayat()
    }
  }, [preferredtaluka])

  const validate = () => {
    let IsformValid = true;

    if (!firstName) {
      IsformValid = false;
      setfnameError(true);
    } else if (firstName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (middleName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (!lastName) {
      IsformValid = false;
      setlnameError(true);
    } else if (lastName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (!contact) {
      IsformValid = false;
      setcontactError(true);
    } else if (
      contact.match(/[A-Za-z+@#$&%!~]/) ||
      (contact.length !== 10 && contact.length !== 0)
    ) {
      IsformValid = false;
      setError(true);
    }

    if (!email) {
      IsformValid = false;
      setEmailError(true);
    } else if (
      !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) &&
      email.length !== 0
    ) {
      IsformValid = false;
      setError(true);
    }

    if (!password) {
      IsformValid = false;
      setPassError(true);
    }

    if (!currentAdd) {
      IsformValid = false;
      setcurrAddError(true);
    }

    if (!pincode) {
      IsformValid = false;
      setpinError(true);
    } else if (
      pincode.match(/[A-Za-z+@#$&%!~]/) ||
      (pincode.length !== 6 && pincode.length !== 0)
    ) {
      IsformValid = false;
      setError(true);
    }

    if (!usertype) {
      IsformValid = false;
      setusertypeError(true);
    }

    if (!District) {
      IsformValid = false;
      setcurrAdddistrictError(true);
    }

    if (!taluka) {
      IsformValid = false;
      setcurrAddtalukaError(true);
    }

    if (!GramPanchayat) {
      IsformValid = false;
      setcurrAddgrampanchaytError(true);
    }
    if (!whatsup) {
      setwhatsupcontactError(true);
    }
    if (!currentAddlinetwo) {
      setcurrAddtwoError(true);
    }
    if (!currentstate) {
      setcurrAddstateError(true);
    }
    if (!taluka) {
      setcurrAddtalukaError(true);
    }
    if (!preferredGramPanchayat) {
      setpreffAddgrampanchayatError(true);
    }
    if (!preferredtaluka) {
      setpreffAddtalukaError(true);
    }
    if (!preferredDistrict) {
      setpreffAdddistrictError(true);
    }
    return IsformValid;
  };

  const uploadImageToS3 = (event) => {
    setImgUploading(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + "/" + TrainingModuleName,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: "https://gsda-images2.s3.ap-south-1.amazonaws.com",
    };

    let fileInput = false;
    const image = event;
    // console.log("image data before compression-------------- ", image);
    if (event) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.9,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_";
          // console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              // setImage(res.location);

              if (res.status === 204) {
                setImgUploading(false);
                // console.log("uploded image", res);
                // console.log("image uploaded successfully!!!!");
                setImgArray(res.location);
                setLoader(false);
              } else {
                setImgUploadingError(true);
                setLoader(false);
              }
              //   if (type === "ProfilePic") {
              //     // if (image !== null && image !== userDetails?.profilePhotoURL) {
              //     //   // updateProfilePic({ profilePhotoURL: res.location, user_Id: userDetails?._id })
              //     // }
              //   } else if (type === "Logo1") {
              //     // setlogo1(res.location)
              //   } else if (type === "Logo2") {
              //     // setlogo2(res.location)
              //   }
            })
            .catch((err) => {
              console.error(err);
            });
        },
      });
      // console.log("--------------- Upload image -----------");
    }
  };

  const handleChange = async (e) => {
    // console.log("Changing Profile pic", e.target.files);
    // if (e.target.files.length > categoryName.qty)
    //   alert("Cannot upload more than 10 Images")
    if (e.target.files.length) {
      const img = URL.createObjectURL(e.target.files[0]);
      // console.log("Files Type", e.target.files[0]);
      setLoader(true);
      uploadImageToS3(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    setfnameError(false);
    setlnameError(false);
    setEmailError(false);
    setcontactError(false);
    setpinError(false);
    setPassError(false);
    setcurrAddError(false);
    setPermaAddError(false);
    setusertypeError(false);
    setdistError(false);
    settalukaError(false);
    setGramPanchayatError(false);
    setcurrAddtalukaError(false);
    setcurrAddgrampanchaytError(false);
    setcurrAddtwoError(false);
    setwhatsupcontactError(false);
    setGramPanchayatError(false);
    setcurrAddstateError(false);
    setpreffAdddistrictError(false);
    setpreffAddtalukaError(false);
    setpreffAddgrampanchayatError(false);
    setcurrAdddistrictError(false);
    if (validate()) {
      //   const firstName = firstName;
      //    const middleName = middleName;
      //   const lastName = lastName;
      const contactNo = contact;
      const emailId = email;

      const whatsappNo = whatsup;
      const currentAddressLine1 = currentAdd;
      const currentAddressLine2 = currentAddlinetwo;
      const currentAddressPincode = pincode;

      const currentAddressGrampanchayat = GramPanchayat?.schoolName;
      const currentAddressState = currentstate;
      const currentAddressTaluka = taluka?.talukaName;

      const currentAddressDistrict = District?.districtName;
      const permanentAddressLine1 = permanentAdd;
      const permanentAddressLine2 = permanentAddlinetwo;
      const permanentAddressPincode = permanentpincode;
      const permanentAddressTaluka = Permanenttaluka?.talukaName;
      const permanentAddressDistrict = PermanentDistrict?.districtName;
      const permanentAddressState = permanentstate;
      const permanentAddressGrampanchayat =
        PermanentGramPanchayat?.schoolName;
      const preferredGrampanchayat_id = preferredGramPanchayat._id;
      const preferredGrampanchayatName =
        preferredGramPanchayat?.schoolName;
      const preferredTalukaName = preferredtaluka?.talukaName;
      const preferredTaluka_id = preferredtaluka?._id;
      const preferredDistrict_id = preferredDistrict?._id;
      const preferredDistrictName = preferredDistrict?.districtName;
      const profilePicURL = imgArray;
      const userType = usertype?.key;
      //   const password = password;

      const userData = {
        firstName,
        middleName,
        lastName,
        contactNo,
        whatsappNo,
        emailId,
        currentAddressLine1,
        currentAddressLine2,
        currentAddressPincode,
        currentAddressTaluka,
        currentAddressDistrict,
        currentAddressState,
        currentAddressGrampanchayat,
        permanentAddressLine1,
        permanentAddressLine2,
        permanentAddressPincode,
        permanentAddressTaluka,
        permanentAddressDistrict,
        permanentAddressState,
        permanentAddressGrampanchayat,
        preferredGrampanchayat_id,
        preferredGrampanchayatName,
        preferredTaluka_id,
        preferredTalukaName,
        preferredDistrict_id,
        preferredDistrictName,
        password,
        profilePicURL,
        userType,
      };
      setloading(true);
      await axios
        .post(process.env.REACT_APP_BASE_URL + "/user/insertuser", userData)
        .then((responce) => {
          if (responce.data !== "Mobile number already exist") {
            setloading(false);
            navigate("/admin/user-list");
            // console.log("responce from add user", responce.data);
          }else{
            setloading(false)
            setUserExist(true)
          }
        })
        .catch((err) => {
          setloading(false);
          // console.log("error in add user", err);
        });
      // console.log("Form Valid");
    } else {
      // console.log("Form Invalid");
    }
  };

  return (
    <center>
      <div className={classes.mainClass}>
        <Typography
          variant="h3"
          sx={{ marginTop: 7, fontWeight: 600 }}
          component="h2"
        >
          ADD USER
        </Typography>

        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          endIcon={<SendIcon />}
          onClick={(e) => {
            navigate("/admin/user-list");
          }}
        >
          Go back
        </Button>
        <div>
          <Paper className={classes.profilemodal}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#1d5a96', color: 'white', padding: 10, letterSpacing: '1px' }}>Personal Details</Typography>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-fname"
                      label="First Name"
                      error={firstName.trim().match(/[0-9+@#$&%!~]/)}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      helperText={
                        fnameError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter First Name
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={middleName.trim().match(/[0-9+@#$&%!~]/)}
                      id="outlined-adornment-middle"
                      label="Middle-Name"
                      value={middleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={lastName.match(/[0-9+@#$&%!~]/)}
                      id="outlined-adornment-lname"
                      label="Last-Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      helperText={
                        lnameError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter last Name
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={
                        !email.match(
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                        ) && email.length !== 0
                      }
                      id="outlined-adornment-TrainerName"
                      label="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      helperText={
                        emailError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter email
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={
                        contact.match(/[A-Za-z+@#$&%!~]/) ||
                        (contact.length !== 10 && contact.length !== 0)
                      }
                      id="outlined-adornment-TrainerName"
                      label="Contact No"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      helperText={
                        contactError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter Conatct Number
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={
                        whatsup.match(/[A-Za-z+@#$&%!~]/) ||
                        (whatsup.length !== 10 && whatsup.length !== 0)
                      }
                      id="outlined-adornment-TrainerName"
                      label="Whatsup Number"
                      value={whatsup}
                      onChange={(e) => setwhatsup(e.target.value)}
                      helperText={
                        whatsupcontactError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter whatsup Number
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>
                <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#1d5a96', color: 'white', padding: 10, letterSpacing: '1px' }}>Current Address Details</Typography>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }} >
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-TrainerName"
                      label="Current-Address line 1"
                      value={currentAdd}
                      onChange={(e) => setCurrentAdd(e.target.value)}
                      helperText={
                        currAddError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter current Address line 1
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-TrainerName"
                      label="Current-Address line 2"
                      value={currentAddlinetwo}
                      onChange={(e) => setcurrentAddlinetwo(e.target.value)}
                      helperText={
                        currAddtwoError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter current Address line 2
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-TrainerName"
                      error={firstName.trim().match(/[0-9+@#$&%!~]/)}
                      label="current address state"
                      value={currentstate}
                      onChange={(e) => setcurrentstate(e.target.value)}
                      helperText={
                        currAddstateError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter current Address state
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      current address District
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="current address District"
                      value={District}
                      onChange={(e) => setDistrict(e.target.value)}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {DistrictData !== "No data  found" &&
                        DistrictData.length > 0
                        ? DistrictData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.districtName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {currAdddistrictError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select district data
                      </span>
                    ) : null}
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      current address Taluka
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="current address Taluka"
                      value={taluka}
                      disabled={TalukaData.length === 0}
                      onChange={(e) => setTaluka(e.target.value)}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {TalukaData !== "No data  found" &&
                        TalukaData.length > 0
                        ? TalukaData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.talukaName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {currAddtalukaError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select current address taluka
                      </span>
                    ) : null}
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      current address School
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="current address School"
                      value={GramPanchayat}
                      disabled={GramPanchayatData.length === 0}
                      onChange={(e) => setGramPanchayat(e.target.value)}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {GramPanchayatData !== "No data  found" &&
                        GramPanchayatData.length > 0
                        ? GramPanchayatData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.schoolName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>

                    {currAddgrampanchaytError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select current address School
                      </span>
                    ) : null}
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center' }}>

                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={
                        pincode.match(/[A-Za-z+@#$&%!~]/) ||
                        (pincode.length !== 6 && pincode.length !== 0)
                      }
                      id="outlined-adornment-TrainerName"
                      label="current address Pin-Code "
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      helperText={
                        pinError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter current address pincode
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>

                {/* <CheckBox value={Same} /> */}
                <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#1d5a96', color: 'white', padding: 10, letterSpacing: '1px' }}>Permanant Address Details</Typography>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-TrainerName"
                      label="Permanent-Address line 1"
                      value={permanentAdd}
                      onChange={(e) => setPermanentAdd(e.target.value)}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-TrainerName"
                      label="permanent Address line 2"
                      value={permanentAddlinetwo}
                      onChange={(e) => setpermanentAddlinetwo(e.target.value)}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={firstName.trim().match(/[0-9+@#$&%!~]/)}
                      id="outlined-adornment-TrainerName"
                      label="permanent address state "
                      value={permanentstate}
                      onChange={(e) => setpermanentstate(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      Permanent District
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="Permanent District"
                      value={PermanentDistrict}
                      onChange={(e) => setPermanentDistrict(e.target.value)}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {PermanantDistrictData !== "No data  found" &&
                        PermanantDistrictData.length > 0
                        ? PermanantDistrictData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.districtName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      Permanent Taluka
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="Permanent Taluka"
                      value={Permanenttaluka}
                      disabled={PermanantTalukaData.length === 0}
                      onChange={(e) => setPermanentTaluka(e.target.value)}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {PermanantTalukaData !== "No data  found" &&
                        PermanantTalukaData.length > 0
                        ? PermanantTalukaData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.talukaName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      Permanent School
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="Permanent School"
                      value={PermanentGramPanchayat}
                      disabled={PermanantPanchayatData.length === 0}
                      onChange={(e) =>
                        setPermanentGramPanchayat(e.target.value)
                      }
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {PermanantPanchayatData !== "No data  found" &&
                        PermanantPanchayatData.length > 0
                        ? PermanantPanchayatData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.schoolName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center' }}>

                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={
                        permanentpincode.match(/[A-Za-z+@#$&%!~]/) ||
                        (permanentpincode.length !== 6 && permanentpincode.length !== 0)
                      }
                      id="outlined-adornment-TrainerName"
                      label="permanent address Pin-Code "
                      value={permanentpincode}
                      onChange={(e) => setpermanentPincode(e.target.value)}
                    />
                  </FormControl>

                </div>

                {/* <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#1d5a96', color: 'white', padding: 10, letterSpacing: '1px' }}>Preferred Location Details</Typography>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>

                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      preferred District
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="preferred District"
                      value={preferredDistrict}
                      onChange={(e) => setpreferredDistrict(e.target.value)}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {PreferredDistrictData !== "No data  found" &&
                        PreferredDistrictData.length > 0
                        ? PreferredDistrictData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.districtName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {preffAdddistrictError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select preffered address district
                      </span>
                    ) : null}
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      preferred Taluka
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="preferred Taluka"
                      value={preferredtaluka}
                      disabled={PreferredTalukaData.length === 0}
                      onChange={(e) => setpreferredTaluka(e.target.value)}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {PreferredTalukaData !== "No data  found" &&
                        PreferredTalukaData.length > 0
                        ? PreferredTalukaData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.talukaName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {preffAddtalukaError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select preffered address taluka
                      </span>
                    ) : null}
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      preferred GramPanchayat
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="preferred GramPanchayat"
                      value={preferredGramPanchayat}
                      disabled={PreferredPanchayatData.length === 0}
                      onChange={(e) =>
                        setpreferredGramPanchayat(e.target.value)
                      }
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {PreferredPanchayatData !== "No data  found" &&
                        PreferredPanchayatData.length > 0
                        ? PreferredPanchayatData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.grampanchayatName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {preffAddgrampanchayatError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select preffered address taluka
                      </span>
                    ) : null}
                  </FormControl>
                </div> */}

                <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#1d5a96', color: 'white', padding: 10, letterSpacing: '1px' }}>Password & Type of Role</Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                    , flexWrap: 'wrap'
                  }}
                >

                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        passError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter password
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                      User-Type
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-bank"
                      label="User-Type"
                      value={usertype}
                      onChange={(e) => setUserType(e.target.value)}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {User !== "No data  found" && User.length > 0
                        ? User.map((option) => (
                          <MenuItem key={option.id} value={option}>
                            {option.label}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {lnameError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select user type
                      </span>
                    ) : null}
                  </FormControl>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center", flexWrap: 'wrap'
                  }}
                >
                  {/* <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 250 }}
                    id="outlined-adornment-TrainerName"
                    label="Profile picture"
                    value={profilePicURL}
                    onChange={(e) => setprofilePicURL(e.target.value)}
                    // helperText={
                    //   lnameError ? (
                    //     <span style={{ color: "red", fontSize: 15 }}>
                    //       *Please Enter last Name
                    //     </span>
                    //   ) : null
                    // }
                  />
                </FormControl> */}
                  {/* <FormControl sx={{ m: 1, marginRight: 3, marginTop: 4 }}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Button variant="contained" component="label">
                          Upload Profile file
                          <input
                            accept={
                              TrainingModuleName
                                ? TrainingModuleName.type
                                : "image/*"
                            }
                            className={classes.input}
                            trainingModule_id="icon-button-file"
                            type="file"
                            onChange={(e) => handleChange(e)}
                            style={{ display: "none" }}
                          />
                        </Button>
                        {imageError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            * Please Upload Image
                          </span>
                        ) : null}
                        {imgArray ? (
                          <img
                            src={imgArray}
                            style={{ width: "80px", height: "80px" }}
                          />
                        ) : null}
                        {loader ? <CircularProgress /> : null}
                      </Stack>
                    </FormControl> */}
                </div>
              </CardContent>
              {error ? (
                <Alert
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Please fill requested form properly
                </Alert>
              ) : null}
              <Button
                variant="contained"
                style={{ margin: 10 }}
                endIcon={<SendIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </div>
        <Modal
          className={classes.middlePosition}
          open={userExist}
          onClose={(e) => {
            e.preventDefault();
            setUserExist(false)
          }}
        >
          <Paper className={classes.passmodal}>
            <Stack direction="row" justifyContent="space-between"
              alignItems="center" spacing={2}>
              <Stack direction="column">
                <Typography variant='h6' component='div'>This Mobile Number is already resgistered.
                </Typography>
              </Stack>
              <IconButton aria-label="delete" onClick={e => {
                e.preventDefault();
                setUserExist(!userExist);
              }}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <center>
              <Button variant="contained" style={{ margin: 5 }} onClick={() => setUserExist(!userExist)} >OK</Button>
            </center>
          </Paper>
        </Modal>
      </div>
    </center >
  );
};
const useStyles = makeStyles((theme) => ({
  mainClass: {
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  card: {
    width: "800px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "450px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: 'intrinsic',
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column'
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
}));
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getAllTalukaData: () => dispatch(Action.getAllTalukaData()),
    getAllgrampanchayatdata: () => dispatch(Action.getAllgrampanchayatdata()),
    getDistrictWiseTalukaData: (data) => dispatch(Action.getDistrictWiseTalukaData(data)),
    getTalukaWisePanchayat: (data) => dispatch(Action.getTalukaWisePanchayat(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
