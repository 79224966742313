import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Card } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Image1 from "../../Assets/Image/satara_Mandave.JPG"
import Image2 from "../../Assets/Image/satara_Mandave_2.jpg"
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
require('../../App.css')





export const GpDetailsView = ({ userDetails }) => {

    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation()
    const Trainer = location.state

    const [openModal, setOpenModal] = useState(false);

    // console.log("Data", Trainer)
    // console.log("Details", userDetails)

    return (
        <div className={classes.main}>
            <Card className={classes.card} style={{ backgroundColor: 'whitesmoke' }}>
                <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                    <IconButton style={{ margin: 10, padding: 0 }}>
                        <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#1d5a96' }} sx={{
                        }} >School Details</Typography>
                    </IconButton>
                </div>
                <div>
                    <Table>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>School:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>Amalner</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>District Co-Ordinator:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>J.K. &#40;8893489833&#41;</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>District Co-Ordinator:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>J.K. &#40;8893489833&#41;</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Sarpanch Name:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>A.K. &#40;9990009090&#41;</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Gram Sevak Name:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>R.K. &#40;9990009090&#41;</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Assignment Date:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>12/12/2022</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Shift:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>ABC@gmail.com</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Module Description:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>pune</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Support Trainer Name:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>vaibhav</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Trainer Mobile:</TableCell>
                            <TableCell style={{ fontSize: 20 }}>9989443333</TableCell>
                        </TableRow>
                    </Table>
                </div>
                <center>
                    <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)}>Back</Button>
                </center>
            </Card>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '1200px',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '1000px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
    image: {
        width: 150,
        height: 150
    },
    imageApprove: {
        backgroundColor: 'green',
        color: 'white'
    },
    imageReject: {
        backgroundColor: 'red',
        color: 'white'
    }
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GpDetailsView)