import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import PersonIcon from "@mui/icons-material/Person";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import logo from "../Assets/Image/gsda_logo.jpg";
import LogoutIcon from "@mui/icons-material/Logout";
import * as Action from "../actions/adminAction";
import * as Auth from "../actions/authActions";
import Profile from "../Assets/Image/Profile Admin.png";
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore, TextSnippetOutlined, TopicOutlined } from "@mui/icons-material";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ManIcon from '@mui/icons-material/Man';
import GroupsIcon from '@mui/icons-material/Groups';
import DetailsIcon from '@mui/icons-material/Details';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GiteIcon from '@mui/icons-material/Gite';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ApprovalIcon from '@mui/icons-material/Approval';
import SourceIcon from '@mui/icons-material/Source';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import CategoryIcon from '@mui/icons-material/Category';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SummarizeIcon from '@mui/icons-material/Summarize';

const drawerWidth = 240;

function Admin(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { window } = props;
  const [userList, setUserList] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [AssignDrop, setAssignDrop] = React.useState(false);
  const [Grampanchyat, setGrampanchyat] = React.useState(false);
  const [District, setDistrict] = React.useState(false);
  const [Taluka, setTaluka] = React.useState(false);
  const [TrainingDrop, setTrainingDrop] = useState(false);
  const [DocumentDrop, setDocumentDrop] = React.useState(false);
  const [TrainingReportDrop,setTrainingReportDrop] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List
        sx={{
          marginTop: 3.5,
          height: "100%",
        }}
      >
        {/* Home */}
        <ListItem
          button
          onClick={() => {
            navigate("/admin/dashboard");
            props.setActiveList("/admin/dashboard");
          }}
          sx={{
            paddingTop:2,
            paddingBottom:2,
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={
            props.ActiveList === "/admin/dashboard"
              ? {
                backgroundColor: "#f4c03e",
                border: "1px solid black",
                color: "white",
                fontSize: 20,
              }
              : {
                color: "white",
              }
          }
        >
          <Typography
            style={{
              display: "flex",
              color: "white",

            }} >

            <div className={classes.back}>


              <ListItemButton>

                <ListItemIcon>
                  <HomeIcon style={{ color: "white", fontSize: 25 }} />
                </ListItemIcon>
                <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Home</Typography>
              </ListItemButton>
            </div>
          </Typography>
        </ListItem>

        {/* user */}
        {/* Assign */}
        <ListItem
          button
          onClick={() => setAssignDrop(!AssignDrop)}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 24,
            },
          }}
          style={{ color: "white", display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              display: "flex",
              color: "white",
              paddingLeft: 20,
              paddingTop: 10,
              alignItems: "center",
              paddingBottom: 10

            }} >

            <ListItemIcon>
              <DetailsIcon style={{ color: "white", fontSize: 25 }} />
            </ListItemIcon>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
              Trainer Assign Details
            </Typography>
          </Typography>
          {AssignDrop ? (
            <ExpandLess style={{ marginLeft: 4 }} />
          ) : (
            <ExpandMore style={{ marginLeft: 4 }} />
          )}
        </ListItem>

        {/* Assign Dropdown */}
        <Collapse in={AssignDrop} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/assign-trainer");
                props.setActiveList("/admin/assign-trainer");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/assign-trainer"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <ManIcon style={{ color: "white", fontSize: 20 }} />

                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    Assign School
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/assigned-trainer-list");
                props.setActiveList("/admin/assigned-trainer-list");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/assigned-trainer-list"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <GroupsIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    Assigned Trainer List
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>
          </List>
        </Collapse>

        {/* Beneficiery Details */}
        <ListItem
          button
          onClick={() => {
            navigate("/admin/view-Beneficiery-list");
            props.setActiveList("/admin/view-Beneficiery-list");
          }}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={
            props.ActiveList === "/admin/view-Beneficiery-list"
              ? {
                backgroundColor: "#f4c03e",
                border: "1px solid black",
                color: "white",
                fontSize: 20,
              }
              : {
                color: "white",
              }
          }
        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon>
                <GroupsIcon style={{ color: "white", fontSize: 25 }} />
              </ListItemIcon>
              <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Training Beneficiery</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Assign District */}
        <ListItem
          button
          onClick={() => {
            navigate("/admin/Assign-DistrictQC");
            props.setActiveList("/admin/Assign-DistrictQC");
          }}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={
            props.ActiveList === "/admin/Assign-DistrictQC"
              ? {
                backgroundColor: "#f4c03e",
                border: "1px solid black",
                color: "white",
                fontSize: 20,
              }
              : {
                color: "white",
              }
          }
        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon>
                <GroupsIcon style={{ color: "white", fontSize: 25 }} />
              </ListItemIcon>
              <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Assign District to QC</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        <ListItem
          button
          onClick={() => {
            navigate("/admin/Assign-DistrictDC");
            props.setActiveList("/admin/Assign-DistrictDC");
          }}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={
            props.ActiveList === "/admin/Assign-DistrictDC"
              ? {
                backgroundColor: "#f4c03e",
                border: "1px solid black",
                color: "white",
                fontSize: 20,
              }
              : {
                color: "white",
              }
          }
        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon>
                <GroupsIcon style={{ color: "white", fontSize: 25 }} />
              </ListItemIcon>
              <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Assign District to DC</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* district  */}
        <ListItem
          button
          onClick={() => {
            setDistrict(!District)
            props.setActiveList("/admin/all-district-list");
          }}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between"
          }}

        >
          <Typography
            style={{
              display: "flex",
              color: "white",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center"

            }} >

            <ListItemIcon>

              <LocationCityIcon style={{ color: "white", fontSize: 25 }} />
            </ListItemIcon>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>District</Typography>

          </Typography>
          {District ? (
            <ExpandLess style={{ marginLeft: 10 }} />
          ) : (
            <ExpandMore style={{ marginLeft: 10 }} />
          )}
        </ListItem>
        {/* district Dropdown */}
        <Collapse in={District} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/all-district-list");
                props.setActiveList("/admin/all-district-list");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/all-district-list"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <DataThresholdingIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    District Data
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>
          </List>
        </Collapse>

        {/* Taluka*/}
        <ListItem
          button
          onClick={() => setTaluka(!Taluka)}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between"


          }}
        >
          <Typography
            style={{
              display: "flex",
              color: "white",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center"

            }} >

            <ListItemIcon>
              <HomeWorkIcon style={{ color: "white", fontSize: 25 }} />
            </ListItemIcon>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Taluka</Typography>
          </Typography>
          {Taluka ? (
            <ExpandLess style={{ marginLeft: 5 }} />
          ) : (
            <ExpandMore style={{ marginLeft: 5 }} />
          )}
        </ListItem>
        {/* Taluka Dropdown */}
        <Collapse in={Taluka} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/all-taluka-list");
                props.setActiveList("/admin/all-taluka-list");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/all-taluka-list"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <DataThresholdingIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>Taluka Data</Typography>
                </ListItemButton>
              </div>
            </ListItem>
          </List>
        </Collapse>
        {/* GramPanchayat */}
        <ListItem
          button
          onClick={() => setGrampanchyat(!Grampanchyat)}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between"


          }}
        >
          <Typography
            style={{
              display: "flex",
              color: "white",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",


            }} >

            <ListItemIcon>
              <GiteIcon style={{ color: "white", fontSize: 25 }} />
            </ListItemIcon>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>School List</Typography>
          </Typography>
          {Grampanchyat ? (
            <ExpandLess style={{ marginLeft: 5 }} />
          ) : (
            <ExpandMore style={{ marginLeft: 5 }} />
          )}
        </ListItem>

        {/* GramPanchyat Dropdown */}
        <Collapse in={Grampanchyat} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/all-panchyat-list");
                props.setActiveList("/admin/all-panchyat-list");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/all-panchyat-list"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <DataThresholdingIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    School Data
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>
            {/* <ListItem
              button
              onClick={() => {
                navigate("/admin/assign-Panchyat");
                props.setActiveList("/admin/assign-Panchyat");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/assign-Panchyat"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <AssignmentIndIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 15 }}>
                    Assign Module
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem> */}
          </List>
        </Collapse>

        {/* Document  */}
        <ListItem
          button
          onClick={() => setDocumentDrop(!DocumentDrop)}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between"

          }}
        >
          <Typography
            style={{
              display: "flex",
              color: "white",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center"

            }} >

            <ListItemIcon>
              <SourceIcon style={{ color: "white", fontSize: 25 }} />
            </ListItemIcon>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Trainer Document</Typography>
          </Typography>
          {DocumentDrop ? (
            <ExpandLess style={{ marginLeft: 15 }} />
          ) : (
            <ExpandMore style={{ marginLeft: 15 }} />
          )}
        </ListItem>
        {/* Document Dropdown */}
        <Collapse in={DocumentDrop} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/upload-document");
                props.setActiveList("/admin/upload-document");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/upload-document"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <UploadFileIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    Upload Training Documents
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/Approved-document");
                props.setActiveList("/admin/Approved-document");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/Approved-document"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <ApprovalIcon style={{ color: "white", fontSize: 20 }} />


                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    Approved Training Documents
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/PendingList");
                props.setActiveList("/admin/PendingList");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/PendingList"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <ApprovalIcon style={{ color: "white", fontSize: 20 }} />


                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    Pending Training 
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>
          </List>
        </Collapse>

        {/* Training Report */}
        <ListItem
          button
          onClick={() => setTrainingReportDrop(!TrainingReportDrop)}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={{
            color: "white",
            justifyContent: "space-between"


          }}
        >
          <Typography
            style={{
              display: "flex",
              color: "white",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center"

            }} >

            <ListItemIcon>
              <AssessmentIcon style={{ color: "white", fontSize: 25 }} />
            </ListItemIcon>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Training Report</Typography>
          </Typography>

          {TrainingReportDrop ? (
            <ExpandLess style={{ marginLeft: 15, }} />
          ) : (
            <ExpandMore style={{ marginLeft: 1 }} />
          )}
        </ListItem>
        {/* Traning Report Dropdown */}
        <Collapse in={TrainingReportDrop} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>

            <ListItem
              button
              onClick={() => {
                navigate("/admin/generate-training-reportList");
                props.setActiveList("/admin/generate-training-reportList");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/generate-training-reportList"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <SummarizeIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    Generate Report
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>

            <ListItem
              button
              onClick={() => {
                navigate("/admin/consolidated-district-List");
                props.setActiveList("/admin/consolidated-district-List");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/consolidated-district-List"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <TopicOutlined style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    Consolidated District List
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>

            <ListItem
              button
              onClick={() => {
                navigate("/admin/consolidated-trainer-List");
                props.setActiveList("/admin/consolidated-trainer-List");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/consolidated-trainer-List"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <TextSnippetOutlined style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    Consolidated Trainer List
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>
            
          </List>
        </Collapse>

        {/* Traning  */}
        <ListItem
          button
          onClick={() => setTrainingDrop(!TrainingDrop)}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={{
            color: "white",
            justifyContent: "space-between"


          }}
        >
          <Typography
            style={{
              display: "flex",
              color: "white",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center"

            }} >

            <ListItemIcon>
              <ModelTrainingIcon style={{ color: "white", fontSize: 25 }} />
            </ListItemIcon>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Training</Typography>
          </Typography>

          {TrainingDrop ? (
            <ExpandLess style={{ marginLeft: 15, }} />
          ) : (
            <ExpandMore style={{ marginLeft: 1 }} />
          )}
        </ListItem>
        {/* Traning Dropdown */}
        <Collapse in={TrainingDrop} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/training-module");
                props.setActiveList("/admin/training-module");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/training-module"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <ViewModuleIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>Module</Typography>
                </ListItemButton>
              </div>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/training-category");
                props.setActiveList("/admin/training-category");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/training-category"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <CategoryIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>
                    Traninig Category
                  </Typography>
                </ListItemButton>
              </div>
            </ListItem>
          </List>
        </Collapse>

        {/* Add user */}
        <ListItem
          button
          onClick={() => setUserList(!userList)}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}

        >
          <Typography
            style={{
              display: "flex",
              color: "white",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center"

            }} >

            <ListItemIcon >
              <PersonIcon style={{ color: "white", fontSize: 25 }} />
            </ListItemIcon>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>All User </Typography>
          </Typography>
          <Typography >

            {userList ? (
              <ExpandLess style={{ marginLeft: 5, color: "white", }} />
            ) : (
              <ExpandMore style={{ marginLeft: 5, color: "white", }} />
            )}
          </Typography>
        </ListItem>

        {/* Add user Dropdown*/}
        <Collapse in={userList} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={() => {
                navigate("/admin/user-list");
                props.setActiveList("/admin/user-list");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/user-list"
                  ? {
                    backgroundColor: "#f4c03e",
                    border: "1px solid black",
                    color: "white",
                    fontSize: 20,
                  }
                  : {
                    color: "white",
                    backgroundColor: "#043463",
                  }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <GroupsIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>User List</Typography>
                </ListItemButton>
              </div>
            </ListItem>
            {/* <ListItem
              button
              onClick={() => {
                navigate("/admin/add-user");
                props.setActiveList("/admin/add-user");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/add-user"
                  ? {
                      backgroundColor: "#f4c03e",
                      border: "1px solid black",
                      color: "white",
                      fontSize: 20,
                    }
                  : {
                      color: "white",
                      backgroundColor: "#043463",
                    }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <PersonAddAltIcon style={{ color: "white", fontSize: 20 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 14 }}>Add user</Typography>
                </ListItemButton>
              </div>
            </ListItem> */}
            {/* <ListItem
              button
              onClick={() => {
                navigate("/admin/edit-user");
                props.setActiveList("/admin/edit-user");
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#f4c03e",
                  border: "1px solid black",
                  fontSize: 20,
                },
              }}
              style={
                props.ActiveList === "/admin/edit-user"
                  ? {
                      backgroundColor: "#f4c03e",
                      border: "1px solid black",
                      color: "white",
                      fontSize: 20,
                    }
                  : {
                      color: "white",
                      backgroundColor: "#043463",
                    }
              }
            >
              <div className={classes.back}>
                <ListItemButton>
                  <ListItemIcon>
                    <PersonIcon style={{ color: "white", fontSize: 25 }} />
                  </ListItemIcon>
                  <Typography style={{ fontSize: 15 }}>Edit user</Typography>
                </ListItemButton>
              </div>
            </ListItem> */}
          </List>
        </Collapse>

        {/* Logout */}
        <ListItem
          button
          onClick={() => {
            props.logout();
            navigate("/");
          }}
          sx={{
            "&:hover": {
              backgroundColor: "#f4c03e",
              border: "1px solid black",
              fontSize: 20,
            },
          }}
          style={{ color: "white" }}
        >
          <Typography
            style={{
              display: "flex",
              color: "white",
              paddingLeft: 3,
              alignItems: "center"

            }} >

            <div className={classes.back}>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon style={{ color: "white", fontSize: 25 }} />
                </ListItemIcon>
                <Typography style={{ fontSize: 16, fontWeight: "bold" }}>LogOut</Typography>
              </ListItemButton>
            </div>
          </Typography>
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (props.userDetails === null) {
    navigate("/");
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% -0px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          // border: '1px solid #1d5a96',
          zIndex: 1201,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Toolbar style={{ width: "100%", flex:1 }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} style={{ width: 200, height: 90, padding: 10 }} />
        </Toolbar>
          <div style={{ flex:1 }}>
            <Typography style={{ fontSize: 30, color: 'green', fontWeight: 600, margin: '5px',marginTop:'25px' }}>Admin Dashboard</Typography>
          </div>
        <img
          src={
            props.userDetails?.profilePicURL
              ? props.userDetails?.profilePicURL
              : Profile
          }
          style={{
            width: 70,
            height: 70,
            margin: 20,
            cursor: "pointer",
            border: "1px solid black",
            borderRadius: "50%",
          }}
          onClick={() => {
            navigate("/admin/profile");
            props.setActiveList("/admin/profile");
          }}
        />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: '#1d5a96'
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              "::-webkit-scrollbar": {
                display: "none",
              },
              backgroundColor: "#1d5a96",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/* <Typography paragraph> */}
        <Outlet />
        {/* </Typography> */}
      </Box>
    </Box>
  );
}

Admin.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  back: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "180px",
    borderBottomLeftRadius: "30px",
    borderTopLeftRadius: "30px",
  },
  active: {
    backgroundColor: "#f4c03e",
    border: "1px solid black",
    fontSize: 24,
  },
}));

const mapStateToProps = (state) => {
  return {
    ActiveList: state.admin.ActiveList,
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveList: (data) => dispatch(Action.setActiveList(data)),
    logout: () => dispatch(Auth.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
