import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, Autocomplete, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CircularProgress, TextField, FormControl, Modal, Card, IconButton } from "@mui/material";
import * as Action from "../../actions/adminAction"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

export const TrainerReassign = ({ getAllTrainers, userDetails, UnassignTrainer, getSingleTraining, ForcefullyReassign, ReAssignTrainerdata }) => {

    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation()
    const [loader, setLoader] = useState(false)
    const data = location.state

    useEffect(() => {
        getTrainerData()
        getTrainingData()
    }, [])

    //getTraining data
    const getTrainingData = async () => {
        setLoader(true)
        const result = await getSingleTraining({
            trainingAssignTrainer_id: data?._id
        })
        if (result !== "No data found" && result !== undefined) {
            // console.log("Training", result[0])
            setTraining(result[0])
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    //data variables
    const [trainerData, setTrainerData] = useState([])
    const [MainTrainer, setMainTrainer] = useState('')
    const [SupportTrainer, setSupportTrainer] = useState()
    const [Training, setTraining] = useState('')

    //Error variables
    const [MainError, setMainError] = useState(false)
    const [SupportError, setSupportError] = useState(false)
    const [CantBeSame, setCantBeSame] = useState(false)
    const [MainCantBeSame, setMainCantBeSame] = useState(false)

    //Modal variables
    const [AreUSure, setAreUSure] = useState(false)
    const [unassign, setUnassign] = useState(false)
    const [unassignIndex, setUnassignIndex] = useState()
    const [showreAssgin, setShowreAssgin] = useState(false)
    const [reassignIndex, setReassignIndex] = useState()
    const [assignedPanchayat,setAssignedPanchayat] = useState('')

    const getTrainerData = async () => {
        setLoader(true)
        // const result = await UnassignedTrainers({
        //     "startDate": data?.startDate,
        //     "endDate": data?.endDate,
        //     "shift": data?.shift
        // })
        const result = await getAllTrainers()
        if (result) {
            setTrainerData(result)
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    const RemoveTrainer = async () => {
        const result = await UnassignTrainer({
            trainingAssignTrainer_id: Training?._id,
            index: unassignIndex
        })
        if (result) {
            // console.log(result)
            getTrainingData()
            setUnassign(false)
        }
    }

    const openUnassign = (index) => {
        setUnassign(true)
        setUnassignIndex(index)
    }

    const openReasign = (index) => {
        setShowreAssgin(true)
        setMainCantBeSame(false)
        setReassignIndex(index)
    }

    const ReassignTrainer = async () => {
        // console.log("Reassigning Trainer", MainTrainer)
        // console.log("Index", reassignIndex)
        let Trainer_Name = MainTrainer?.firstName + " " + MainTrainer?.lastName
        const result = await ReAssignTrainerdata({
            trainingAssignTrainer_id: Training?._id,
            index: reassignIndex,
            user_id: MainTrainer?._id,
            name: Trainer_Name,
            mobileNo: MainTrainer?.contactNo,
            updatedBy: userDetails?._id
        })
        if (result?.msg === "Trainer already assign") {
            if(result?.data[0]._id === Training?._id){
                // console.log("This trainer is Already Assigned to current GramPanchayat")
                setMainCantBeSame(true)
            }else{
                setAssignedPanchayat(result?.data[0])
                setAreUSure(true)
            }
        }else{
            // console.log("Reassign Trainer", result)
            setShowreAssgin(false)
            getTrainingData()
        }
    }

    const forcefullyAssign = async () => {
        // console.log("Reassigning Trainer", MainTrainer)
        // console.log("Index", reassignIndex)
        setMainCantBeSame(true)
        let Trainer_Name = MainTrainer?.firstName + " " + MainTrainer?.lastName
        const result = await ForcefullyReassign({
            trainingAssignTrainer_id: Training?._id,
            index: reassignIndex,
            user_id: MainTrainer?._id,
            name: Trainer_Name,
            mobileNo: MainTrainer?.contactNo,
            updatedBy: userDetails?._id
        })
        if(result){
            // console.log("Forcefully Assigned Successfully")
            setAreUSure(false)
            setShowreAssgin(false)
            getTrainingData()
        }
    }

    return (
        <div className={classes.main}>
            {
                loader ? <CircularProgress color='primary' /> :
                    Training && <Card className={classes.card} style={{ backgroundColor: 'whitesmoke', width: 'intrinsic' }}>
                        <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                            <IconButton style={{ margin: 10, padding: 0 }}>
                                <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#1d5a96' }} sx={{
                                }} >Training Data</Typography>
                            </IconButton>
                        </div>
                        <TableContainer>
                            <Table>
                                <TableRow>
                                    <TableCell style={{ fontSize: 18 }}>District Name:</TableCell>
                                    <TableCell style={{ fontSize: 18 }}>{Training?.districtName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: 18 }}>School Name:</TableCell>
                                    <TableCell style={{ fontSize: 18 }}>{Training?.grampanchayatName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: 18 }}>Training Module Name:</TableCell>
                                    <TableCell style={{ fontSize: 18 }}>{Training?.trainingModuleName}</TableCell>
                                </TableRow>
                                {Training?.trainerNameAll && Training?.trainerNameAll.length >= 1 && Training?.trainerNameAll[0] !== '' && Training?.trainerNameAll[0] !== null ?
                                    <TableRow>
                                        <TableCell style={{ fontSize: 18 }}>Trainer 1 Name:</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{Training?.trainerNameAll[0] ? Training?.trainerNameAll[0] : '-'}</TableCell>
                                        <TableCell><Button variant='contained' onClick={() => openUnassign(0)}>Unassign</Button></TableCell>
                                    </TableRow> : <TableRow>
                                        <TableCell style={{ fontSize: 18 }}>Trainer 1 Name:</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{Training?.trainerNameAll[0] ? Training?.trainerNameAll[0] : '-'}</TableCell>
                                        <TableCell><Button variant='contained' onClick={() => openReasign(0)}>Reassign</Button></TableCell>
                                    </TableRow>}
                                {Training?.trainerNameAll && Training?.trainerNameAll.length >= 2 && Training?.trainerNameAll[1] !== '' && Training?.trainerNameAll[1] !== null ?
                                    <TableRow>
                                        <TableCell style={{ fontSize: 18 }}>Trainer 2 Name:</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{Training?.trainerNameAll[1] ? Training?.trainerNameAll[1] : '-'}</TableCell>
                                        <TableCell><Button variant='contained' onClick={() => openUnassign(1)}>Unassign</Button></TableCell>
                                    </TableRow> : <TableRow>
                                        <TableCell style={{ fontSize: 18 }}>Trainer 2 Name:</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{Training?.trainerNameAll[1] ? Training?.trainerNameAll[1] : '-'}</TableCell>
                                        <TableCell><Button variant='contained' onClick={() => openReasign(1)}>Reassign</Button></TableCell>
                                    </TableRow>}
                                {Training?.trainerMobileAll && Training?.trainerNameAll.length >= 2 && Training?.trainerMobileAll[0] !== '' ?
                                    <TableRow>
                                        <TableCell style={{ fontSize: 18 }}>Trainier 1 Mobile:</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{Training?.trainerMobileAll[0] ? Training?.trainerMobileAll[0] : '-'}</TableCell>
                                    </TableRow>
                                    : <TableRow>
                                        <TableCell style={{ fontSize: 18 }}>Trainier 1 Mobile:</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{Training?.trainerMobileAll[0] ? Training?.trainerMobileAll[0] : '-'}</TableCell>
                                    </TableRow>}
                                {Training?.trainerMobileAll && Training?.trainerNameAll.length >= 2 && Training?.trainerMobileAll[1] !== '' ?
                                    <TableRow>
                                        <TableCell style={{ fontSize: 18 }}>Trainier 2 Mobile:</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{Training?.trainerMobileAll[1] ? Training?.trainerMobileAll[1] : '-'}</TableCell>
                                    </TableRow>
                                    : <TableRow>
                                        <TableCell style={{ fontSize: 18 }}>Trainier 2 Mobile:</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{Training?.trainerMobileAll[1] ? Training?.trainerMobileAll[1] : '-'}</TableCell>
                                    </TableRow>}
                                <TableRow>
                                    <TableCell style={{ fontSize: 18 }}>Assignment Date:</TableCell>
                                    <TableCell style={{ fontSize: 18 }}>{moment(Training?.startDate).format("DD MMM YYYY")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontSize: 18 }}>Training Shift:</TableCell>
                                    <TableCell style={{ fontSize: 18 }}>{Training?.shift ? Training?.shift?.toUpperCase() : null}</TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                        {/* <Typography style={{ margin: 15, fontSize: 30, fontWeight: 600 }}>Reassign Trainers:</Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ flex: 1 }}>
                                <FormControl sx={{ m: 1, width: 250 }}>
                                    <Autocomplete
                                        id='Trainer-data'
                                        options={trainerData}
                                        disabled={trainerData.length === 0}
                                        autoHighlight
                                        disablePortal
                                        freeSolo
                                        getOptionLabel={(option) => option?.firstName + " " + option?.lastName}
                                        renderInput={(params) => (<TextField {...params} label="Select Trainer" />)}
                                        // value={searchTrainer}
                                        onChange={(event, newValue) => {
                                            setMainTrainer(newValue);
                                        }}
                                    />
                                    {MainError ? <span style={{ color: 'red' }} >*Please SelectTrainer first</span> : null}
                                    {MainCantBeSame ? <span style={{ color: 'red' }} >*Main Trainer and Support Trainer Can't be same.</span> : null}
                                </FormControl>
                                <Button variant='contained' style={{ margin: 10 }} onClick={() => validateReAssign()} >Reassign Trainer</Button>
                            </div>
                            <div style={{ flex: 1 }}>
                                <FormControl sx={{ m: 1, width: 250 }}>
                                    <Autocomplete
                                        id='Trainer-data'
                                        options={trainerData}
                                        autoHighlight
                                        disabled={trainerData.length === 0}
                                        disablePortal
                                        freeSolo
                                        getOptionLabel={(option) => option?.firstName + " " + option?.lastName}
                                        renderInput={(params) => (<TextField {...params} label="Select Support Trainer" />)}
                                        // value={searchTrainer}
                                        onChange={(event, newValue) => {
                                            setSupportTrainer(newValue);
                                        }}
                                    />
                                    {SupportError ? <span style={{ color: 'red' }} >*Please Select Support Trainer first</span> : null}
                                    {CantBeSame ? <span style={{ color: 'red' }} >*Main Trainer and Support Trainer Can't be same.</span> : null}
                                </FormControl>
                                <Button variant='contained' style={{ margin: 10 }} onClick={() => ValidateSupport()} >Reassign Support Trainer</Button>
                            </div>
                        </div> */}
                        <center>
                            <Button variant='contained' style={{ margin: 10 }} onClick={() => navigate(-1)} >Back</Button>
                        </center>
                    </Card>
            }

            <Modal
                className={classes.middlePosition}
                open={AreUSure}
                onClose={(e) => {
                    e.preventDefault()
                    setAreUSure(false)
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography style={{ fontSize:18 }} component='div'>Trainer already assigned in {assignedPanchayat?.districtName} to {assignedPanchayat?.grampanchayatName} School </Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setAreUSure(false);
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
                        <h5>Do you want to forcefully Reassign this Trainer ?</h5>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <Button variant="contained" style={{ backgroundColor: 'green', width: 100, margin: 5 }} onClick={() => {
                                forcefullyAssign()
                            }}>Reassign</Button>

                            <Button variant="contained" style={{ backgroundColor: 'grey', width: 100, margin: 5 }} onClick={() => {
                                setAreUSure(false)
                            }}>Cancel</Button>
                        </div>
                    </div>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={unassign}
                onClose={(e) => {
                    e.preventDefault()
                    setUnassign(false)
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Want to Unassign Trainer from this training ?</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setUnassign(false);
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
                        <h3>Want to Unassign Trainer from this training ?</h3>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <Button variant="contained" style={{ backgroundColor: 'green', width: 100, margin: 5 }} onClick={() => {
                                RemoveTrainer()
                            }}>Unassign</Button>

                            <Button variant="contained" style={{ backgroundColor: 'grey', width: 100, margin: 5 }} onClick={() => {
                                setUnassign(false)
                            }}>Cancel</Button>
                        </div>
                    </div>
                </Paper>
            </Modal>

            <Modal
                className={classes.middlePosition}
                open={showreAssgin}
                onClose={(e) => { e.preventDefault(); setShowreAssgin(false) }}
            >
                <Paper className={classes.profilemodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>Reassign Trainer</Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setShowreAssgin(false);
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 300 }}>
                            <FormControl sx={{ m: 1 }}>
                                <Autocomplete
                                    freeSolo
                                    options={trainerData}
                                    getOptionLabel={(option) => option?.firstName + " " + option?.lastName + " - " + option?.contactNo}
                                    renderInput={(params) => <TextField {...params} label="Select trainer" />}
                                    onChange={(event, newValue) => {
                                        setMainTrainer(newValue);
                                    }}
                                />
                                
                            </FormControl>
                            <Button variant='contained' style={{ margin: 10 }} disabled={MainTrainer === '' || MainTrainer === undefined || MainTrainer === null} onClick={() => ReassignTrainer()} >Reassign Trainer</Button>
                            { MainCantBeSame ? <span style={{ color:'red' }}>*This trainer is Already Assigned to current School</span> : null }
                        </div>
                    </center>
                </Paper>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '60%',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '500px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllTrainers: () => dispatch(Action.getAllTrainers()),
        UnassignedTrainers: () => dispatch(Action.UnassignedTrainers()),
        UnassignTrainer: (data) => dispatch(Action.UnassignTrainer(data)),
        getSingleTraining: (data) => dispatch(Action.getSingleTraining(data)),
        ReAssignTrainerdata: (data) => dispatch(Action.ReAssignTrainerdata(data)),
        ForcefullyReassign: (data) => dispatch(Action.ForcefullyReassign(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainerReassign)