import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Checkbox, FormGroup, FormControlLabel, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, Alert } from "@mui/material";
import * as Action from "../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import axios from "axios";
import { userLogout } from '../actions/authActions';



export const Beneficial = ({ getModule }) => {

    const classes = useStyles();
    const [TrainerData, setTrainerData] = useState([]);
    const [firstName, setFirstName] = useState("");

    const [middleName, setMiddleName] = useState("");

    const [lastName, setLastName] = useState("");

    const [contactNo, setContactNo] = useState("");
    const [whatsappNo, setWhatsappNo] = useState("");
    const [emailId, setEmailId] = useState("");
    const [currentAddressLine1, SetCurrentAddressLine1] = useState("");
    const [currentAddressLine2, SetCurrentAddressLine2] = useState("");
    const [currentAddressPincode, SetCurrentAddressPincode] = useState("");
    const [currentAddressTaluka, SetCurrentAddressTaluka] = useState("");
    const [currentAddressDistrict, SetCurrentAddressDistrict] = useState("");
    const [currentAddressState, SetCurrentAddressState] = useState("");
    const [currentAddressGrampanchayat, SetCurrentAddressGrampanchayat] = useState("");
    const [dob, SetDob] = useState("");
    const [occupation, SetOccupation] = useState([]);
    const [qualification, SetQualification] = useState("");
    const [trainingModule, SetTrainingModule] = useState("");
    const [ModuleData, SetModuleData] = useState([]);
    const [FitfthtoEighth, SetFitfthtoEighth] = useState("");
    const [NinethtoTenthth, SetNinethtoTenthth] = useState("");
    const [Eleventhtotwelveth, SetEleventhtotwelveth] = useState("");
    const [Diploma, SetDiploma] = useState("");
    const [Graduate, SetGraduate] = useState("");
    const [ITI, SetITI] = useState("");
    const [PostGraduate, SetPostGraduate] = useState("");
    const [UnderGraduate, SetUnderGraduate] = useState("");
    const [UnEducated, SetUnEducated] = useState("");
    const [userType, SetUserType] = useState("");
    const [age, SetAge] = useState("");
    const [gender, SetGender] = useState("");

    const quali = [{ value: "FitfthtoEighth" }, { value: "NinethtoTenthth" }, { value: "Eleventhtotwelveth" }, { value: "Diploma" }, { value: "Graduate" }, { value: "ITI" }, { value: "PostGraduate" }, { value: "undergraduate" }, { value: "uneducated" }
    ]

    const gen = [{ value: "Male" }, { value: "Female" }, { value: "Other" }]
    const User = [
        {
            id: 1,
            label: "admin",
        },
        {
            id: 2,
            label: "district coordinator",
        },
        {
            id: 3,
            label: "trainer",
        },
        {
            id: 4,
            label: "gramsevak",
        },
        {
            id: 3,
            label: "sarpanch",
        },
        {
            id: 3,
            label: "beneficiary",
        },
        {
            id: 3,
            label: "quality checker",
        },
    ];






    //API

    const handleSubmit = async () => {

        const data = {
            "firstName": firstName,
            "middleName": middleName,
            "lastName": lastName,
            "contactNo": contactNo,
            "whatsappNo": whatsappNo,
            "emailId": emailId,
            "currentAddressLine1": currentAddressLine1,
            "currentAddressLine2": currentAddressLine2,
            "currentAddressPincode": currentAddressPincode,
            "currentAddressTaluka": currentAddressTaluka,
            "currentAddressDistrict": currentAddressDistrict,
            "currentAddressState": currentAddressState,
            "currentAddressGrampanchayat": currentAddressGrampanchayat,
            "occupation": occupation,
            "qualification": qualification,
            "trainingModule": trainingModule,
            "userType": ["beneficiary"],
            "age": "26",
            "gender": gender,
            "dob": "11-10-2022",

            "password": "123",
        };


        await axios.post(process.env.REACT_APP_BASE_URL+"/beneficiary/insertuser", data)
            .then((res) => res.json())
            .then((res) => {

                // console.log("add beneficial response", res.data);


            })

            .catch((error) => {

                console.error("Error:", error);
            });

    };

    useEffect(() => {

        getModuleData()
    }, [])

    const getModuleData = async () => {
        const result = await getModule()
        // console.log("Modules", result)
        if (result !== "No data found") {
            SetModuleData(result);
        }
    }


    return (
        <div className={classes.main}>
            <Typography
                variant="h3"
                sx={{ marginTop: 7, fontWeight: 600 }}
                component="h2"
            >
                Beneficiary
            </Typography>
            <div className={classes.middlePosition}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="firstName"
                        label="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>


                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="middleName"
                        label="Middle Name"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>


                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="lastName"
                        label="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
            </div>
            <div className={classes.middlePosition}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}

                        id="contactNo"
                        label="Contact Number"
                        value={contactNo}
                        onChange={(e) => setContactNo(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="whatsappNo"
                        label="Whatsapp Number"
                        value={whatsappNo}
                        onChange={(e) => setWhatsappNo(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="emailId"
                        label="Email Id"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
            </div>

            <div className={classes.middlePosition}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="currentAddressLine1"
                        label="Address Line1"
                        value={currentAddressLine1}
                        onChange={(e) => SetCurrentAddressLine1(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="currentAddressLine2"
                        label="Address Line2"
                        value={currentAddressLine2}
                        onChange={(e) => SetCurrentAddressLine2(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="currentAddressPincode"
                        label="Pincode"
                        value={currentAddressPincode}
                        onChange={(e) => SetCurrentAddressPincode(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
            </div>

            <div className={classes.middlePosition}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="currentAddressTaluka"
                        label="Taluka"
                        value={currentAddressTaluka}
                        onChange={(e) => SetCurrentAddressTaluka(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>



                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="currentAddressDistrict"
                        label="District"
                        value={currentAddressDistrict}
                        onChange={(e) => SetCurrentAddressDistrict(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>



                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="state"
                        label="State"
                        value={currentAddressState}
                        onChange={(e) => SetCurrentAddressState(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>

            </div>



            <div className={classes.middlePosition}>

                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="currentAddressGrampanchayat"
                        label="School Address
                        "
                        value={currentAddressGrampanchayat}
                        onChange={(e) => SetCurrentAddressGrampanchayat(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>



                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="occupation"
                        label="Occupation"
                        value={occupation}
                        onChange={(e) => SetOccupation(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>



                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="qualification">
                        Qualification
                    </InputLabel>
                    <Select
                        style={{ width: 300 }}
                        id=""
                        label="qualification"
                        value={qualification}
                        onChange={(e) => SetQualification(e.target.value)}
                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                    >



                        {quali !== "No data  found" &&
                            quali.length > 0
                            ? quali.map((option) => (
                                <MenuItem key={option._id} value={option.value}>
                                    {option.value}
                                </MenuItem>
                            ))
                            : null}


                    </Select>
                </FormControl>
            </div>
            <div className={classes.middlePosition}>

                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                        Training Modulle
                    </InputLabel>
                    <Select
                        style={{ width: 300 }}
                        id=""
                        label="Training Module"
                        value={trainingModule}
                        onChange={(e) => SetTrainingModule(e.target.value)}
                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                    >
                        {ModuleData !== "No data  found" &&
                            ModuleData.length > 0
                            ? ModuleData.map((option) => (
                                <MenuItem key={option._id} value={option.trainingModuleName}>
                                    {option.trainingModuleName}
                                </MenuItem>
                            ))
                            : null}
                    </Select>

                </FormControl>



                {/* <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="outlined-adornment-bank">
                        User-Type
                    </InputLabel>
                    <Select
                        style={{ width: 300 }}
                        id="outlined-adornment-bank"
                        label="User-Type"
                        value={userType}
                        onChange={(e) => SetUserType(e.target.value)}
                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                    >
                        {User !== "No data  found" && User.length > 0
                            ? User.map((option) => (
                                <MenuItem key={option.id} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))
                            : null}
                    </Select>

                </FormControl> */}


                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <InputLabel id="gender">
                        Gender
                    </InputLabel>
                    <Select
                        style={{ width: 300 }}
                        id=""
                        label="gender"
                        value={gender}
                        onChange={(e) => SetGender(e.target.value)}
                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                    >



                        {gen !== "No data  found" &&
                            gen.length > 0
                            ? gen.map((option) => (
                                <MenuItem key={option._id} value={option.value}>
                                    {option.value}
                                </MenuItem>
                            ))
                            : null}


                    </Select>
                </FormControl>

            </div>


            <Button
                variant="contained"
                style={{ margin: 10 }}
                endIcon={<SendIcon />}
                onClick={(e) => {

                    handleSubmit();
                }}
            >
                Submit
            </Button>
        </div>
    );
};
const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    card: {
        width: "800px",
        borderRadius: "20px !important",
        margin: "20px",
        padding: "20px",
    },
    passmodal: {
        width: "350px",
        background: "white",
        borderRadius: "20px !important",
        margin: "20px",
        padding: "20px",
    },
    profilemodal: {
        width: "100%",
        background: "white",
        borderRadius: "20px !important",
        margin: "20px",
        padding: "20px",
    },
    middlePosition: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    input: {
        width: "70%",
        background: "white",
        marginTop: "20px",
        padding: "5px 15px",
    },
}));
const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => {
    return {
        getModule: () => dispatch(Action.getAllTrainingModuleData()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Beneficial)